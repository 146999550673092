import { dayjs } from "helper/DateHelper";


export const DATE_FORMATS = {
  "MM/DD/YYYY": "MM/DD/YYYY",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "MMM DD,YYYY":  "MMM DD,YYYY", 
  "M/DD/YYYY": "MM/DD/YYYY",
  "MM/D/YYYY": "MM/D/YYYY",
  "YYYY/MM/DD": "YYYY/MM/DD",
  "MM/DD/YYYY HH:mm": "MM/DD/YYYY HH:mm",
  "MM/DD/YYYY hh:mm:ss": "MM/DD/YYYY hh:mm:ss",
  "dddd, MMMM D, YYYY": "dddd, MMMM D, YYYY",
};

export const TIME_FORMATS = {
  "HH:MM:a": "HH:MM A",
  "HH:MM": "HH:mm",
  "HH:mm:ss": "HH:mm:ss",
  "hh:mm a": "hh:mm a",
  "hh:mm": "hh:mm",
  "hh:a": "hh A",
};

export const formatDateString = (date, format) => dayjs(date || "").format(format);


