import { useQuery } from "@tanstack/react-query";
import { getPaymentInvoice, getUserSettings } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchUserSettings = (userId) =>
  useQuery({
    queryKey: [
      AppQueriKeys.GET_USER_SETTINGS,
      userId,
    ],
    queryFn: () => getUserSettings(userId),
    enabled: !!userId,
  });
