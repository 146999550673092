import { Route_Url } from "common/constants";
import { isAdmin, isClient, isOfficeManager, isTherapist } from "./ValueHelper";

export const getDefaultRoute = (user) => {
  if (!user) return null;
  if (isClient(user)) {
    return Route_Url.PatientDashboard;
  } else if (isTherapist(user) || isOfficeManager(user)) {
    return Route_Url.DoctorDashboard;
  } else if (isAdmin(user)) {
    return Route_Url.AdminDashboard;
  }
};
