import { useQuery } from "@tanstack/react-query";
import { fetchAppointmentListForTherapist } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchAppointmentList = (pageLimit, pageNumber, sort) =>
  useQuery({
    queryKey: [
      AppQueriKeys.GET_APPOINTMENT_LIST,
      pageLimit,
      pageNumber,
      Object.values(sort).join(""),
    ],
    queryFn: () =>
      fetchAppointmentListForTherapist(pageLimit, pageNumber, "", sort),
    cacheTime: false,
    enabled: pageLimit !== null && pageNumber !== null,
  });
