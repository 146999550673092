import { Storage_Keys, StorageHelper } from "./SessionHelper";

const storePaymentSession = (sessionId) => {
  StorageHelper.store(Storage_Keys.PaymentSessionId, sessionId);
};

const clearPaymentSession = () => {
  StorageHelper.remove(Storage_Keys.PaymentSessionId);
};
const getPaymentSessionId = () => {
  StorageHelper.load(Storage_Keys.PaymentSessionId);
};

export { clearPaymentSession, storePaymentSession, getPaymentSessionId };
