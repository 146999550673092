import { useQuery } from "@tanstack/react-query";
import { getTherapistQuestionTemplate } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchTherapistQuestionTemplate  = (timeframe) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_THERAPIST_QUESTION_TEMPLATE],
    queryFn: () =>
      getTherapistQuestionTemplate(),
  });
