import { useQuery } from "@tanstack/react-query";
import { getPaymentInvoice } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchInvoice = (sessionId) =>
  useQuery({
    queryKey: [
      AppQueriKeys.GET_INVOICE_URL,
      sessionId,
    ],
    queryFn: () => getPaymentInvoice(sessionId),
    enabled: !!sessionId,
    retry: 3,
    
  });
