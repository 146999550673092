import { useQuery } from "@tanstack/react-query";
import { AppQueriKeys } from "./AppQueries";
import { getUsersList } from "services/ApiService";

export const useFetchUserList = ({
  role,
  pageLimit,
  pageNumber,
  sort
}) => {
  return useQuery({
    queryKey: [AppQueriKeys.GET_USER_LIST,role, pageLimit, pageNumber, sort],
    queryFn: () =>
      getUsersList(role, pageLimit, pageNumber, sort),
    enabled: !!role && pageLimit !== null && pageNumber !== null,
  });
}