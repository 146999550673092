import { useQuery } from "@tanstack/react-query";
import {
    fetchInitialAssessmentQuestions
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";


export const useFetchAssessmentQuestions = (apppointmentId, lang) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_ASSESSMENT_QUESTIONS,apppointmentId,lang],
    queryFn: () => fetchInitialAssessmentQuestions(apppointmentId, lang),
    enabled: !!apppointmentId,
    retry: false,
    retryOnMount: false,
  });
