import React from "react";
import { Spin, Typography } from "antd";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
export default function LoadingIndicator(props) {
  const { value, text } = useSelector((state) => state.progress);
  const loadingText = (
    <Typography.Title level={4} editable={false} className="pt-3 text-center">
      {text}
    </Typography.Title>
  );

  return (
    <Spin
      size="large"
      delay={500}
      spinning={value > 0}
      tip={loadingText}
      className={value > 0 ? "mt-[10rem]": ""}
      indicator={<LoadingOutlined className="flex justify-center items-center" style={{ fontSize: 48 }} spin />}
    >
      {props.children}
    </Spin>
  );
}

LoadingIndicator.propTypes = {
  children: PropTypes.node,
};
