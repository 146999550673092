class UserStore {
  constructor() {
    this.user = null;
  }

  setUser(user) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }
}

const userStore = new UserStore();

export { userStore };