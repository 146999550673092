import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useUpdateHistoryState = (newState) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Update the current location state
    navigate(location.pathname, {
      replace: true,
      state: { ...location.state, ...newState },
    });
  }, [newState, location, navigate]);
};

export { useUpdateHistoryState };
