import { useQuery } from "@tanstack/react-query";
import {
    fetchEvidenceSubmissionList
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchEvidenceSubmissionList = (appointmentId) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_EVIDENCE_SUBMISSION, appointmentId],
    queryFn: () => fetchEvidenceSubmissionList(appointmentId),
    enabled: !!appointmentId,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false
  });
