import { useQuery } from "@tanstack/react-query";
import { AppQueriKeys } from "./AppQueries";
import { getAdminClientStats, getAdminPaymentStats } from "services/ApiService";

export const useFetchAdminPaymentStats = (timeframe) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_ADMIN_PAYMENT_STATS, timeframe],
    queryFn: () =>
      getAdminPaymentStats({
        timeframe,
      }),
    enabled: !!timeframe,
  });
