import { useQuery } from "@tanstack/react-query";
import {
    fetchReportRequestDetails
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchAdditionReportRequest = (lang, therapistId) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_ADDITIONAL_REPORT_QUESTIONS, lang, therapistId],
    queryFn: () => fetchReportRequestDetails(lang, therapistId),
    enabled: !!therapistId,
  });
