import { TRANSALATION_TEXT } from "common/constants";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const { en, es } = TRANSALATION_TEXT;
const resources = {
  en: {
    translation: {
      [en.choose_your_appointent_time.key]:
        en.choose_your_appointent_time.value,
      [en.login_text.key]: en.login_text.value,
      [en.calendarOptions.monthly.key]: en.calendarOptions.monthly.value,
      [en.calendarOptions.weekly.key]: en.calendarOptions.weekly.value,
      [en.calendarOptions["today:"].key]: en.calendarOptions["today:"].value,
      [en.previous.key]: en.previous.value,
      [en.next.key]: en.next.value,
      [en.bookAppointment.key]: en.bookAppointment.value,
      [en.reset.key]: en.reset.value,
      [en.language.key]: en.language.value,
      [en.navigation.dashboard.key]: en.navigation.dashboard.value,
      [en.navigation.appointmentList.key]: en.navigation.appointmentList.value,
      [en.navigation.settings.key]: en.navigation.settings.value,
      [en.navigation.appointmentBooking.key]:
        en.navigation.appointmentBooking.value,
      [en.navigation.users.key]: en.navigation.users.value,
      [en.therapistDashboard.appointmentsThisMonth.key]:
        en.therapistDashboard.appointmentsThisMonth.value,
      [en.therapistDashboard.appointmentsLeftToday.key]:
        en.therapistDashboard.appointmentsLeftToday.value,
      [en.therapistDashboard.TodaysAppointments]:
        en.therapistDashboard.TodaysAppointments,
      [en.therapistDashboard.clientsThisMonth.key]:
        en.therapistDashboard.clientsThisMonth.value,
      [en.therapistDashboard.OutstandingBalance.key]:
        en.therapistDashboard.OutstandingBalance.value,
      [en.therapistDashboard.NextAppointmentIn.key]:
        en.therapistDashboard.NextAppointmentIn.value,
      [en.therapistDashboard.TotalEarnings.key]:
        en.therapistDashboard.TotalEarnings.value,
      [en.therapistDashboard.TotalClients.key]:
        en.therapistDashboard.TotalClients.value,
      [en.therapistDashboard.HaveANiceDayAtWork.key]:
        en.therapistDashboard.HaveANiceDayAtWork.value,
      [en.statuses.pending.key]: en.statuses.pending.value,
      [en.statuses.completed.key]: en.statuses.completed.value,
      [en.statuses.onTime.key]: en.statuses.onTime.value,
      [en.statuses.cancelled.key]: en.statuses.cancelled.value,
      [en.statuses.followUp.key]: en.statuses.followUp.value,
      [en.statuses.rescheduled.key]: en.statuses.rescheduled.value,
      [en.initialAssessement.rules.completeTheFollowingStepsBy8AmOnYouApptDay
        .key]:
        en.initialAssessement.rules.completeTheFollowingStepsBy8AmOnYouApptDay
          .value,
      [en.initialAssessement.rules.beforeYourAppointment.key]:
        en.initialAssessement.rules.beforeYourAppointment.value,
      [en.initialAssessement.rules.payANonRefundableDeposit.key]:
        en.initialAssessement.rules.payANonRefundableDeposit.value,
      [en.initialAssessement.rules.depositHere.key]:
        en.initialAssessement.rules.depositHere.value,
      [en.initialAssessement.rules.includeYourFirstAndLastNameInThePaymentNotes
        .key]:
        en.initialAssessement.rules.includeYourFirstAndLastNameInThePaymentNotes
          .value,
      [en.initialAssessement.rules.register.key]:
        en.initialAssessement.rules.register.value,

      [en.initialAssessement.rules.asYourSAPInTheClearinghouseIfApplicable.key]:
        en.initialAssessement.rules.asYourSAPInTheClearinghouseIfApplicable
          .value,
      [en.initialAssessement.rules.fillOutTheNewClientFormAndAssessments.key]:
        en.initialAssessement.rules.fillOutTheNewClientFormAndAssessments.value,
      [en.initialAssessement.rules
        .theseStepsMustBeCompletedToAvoidAutomaticRescheduling.key]:
        en.initialAssessement.rules
          .theseStepsMustBeCompletedToAvoidAutomaticRescheduling.value,
      [en.initialAssessement.rules.minimumIsNeededToSecureYourBooking.key]:
        en.initialAssessement.rules.minimumIsNeededToSecureYourBooking.value,
      [en.initialAssessement.rules.Give48HoursNoticeToCancelOrReschedule.key]:
        en.initialAssessement.rules.Give48HoursNoticeToCancelOrReschedule.value,
      [en.initialAssessement.rules.fee.key]:
        en.initialAssessement.rules.fee.value,
      [en.initialAssessement.rules.SimplyCancelRebook.key]:
        en.initialAssessement.rules.SimplyCancelRebook.value,
      [en.initialAssessement.rules
        .noPreliminaryInformationCallIsNeededForBooking.key]:
        en.initialAssessement.rules
          .noPreliminaryInformationCallIsNeededForBooking.value,
      [en.initialAssessement.rules.contactSapflowAi.key]:
        en.initialAssessement.rules.contactSapflowAi.value,
      [en.followUpAppointment.content1.key]:
        en.followUpAppointment.content1.value,
      [en.followUpAppointment.content2.key]:
        en.followUpAppointment.content2.value,
      [en.followUpAppointment.content3.key]:
        en.followUpAppointment.content3.value,
      [en.followUpAppointment.content4.key]:
        en.followUpAppointment.content4.value,
      [en.followUpAppointment.content5.key]:
        en.followUpAppointment.content5.value,
      [en.followUpAppointment.title.key]: en.followUpAppointment.title.value,
      [en.appointmentBooking.appointmentDate.key]:
        en.appointmentBooking.appointmentDate.value,
      [en.appointmentBooking.appointmentTime.key]:
        en.appointmentBooking.appointmentTime.value,
      [en.appointmentBooking.duration.key]:
        en.appointmentBooking.duration.value,
      [en.appointmentBooking.minutes.key]: en.appointmentBooking.minutes.value,
      [en.appointmentBooking.appointmentDetails.key]:
        en.appointmentBooking.appointmentDetails.value,
        [en.appointmentBooking.days.Monday.key]: en.appointmentBooking.days.Monday.value,
        [en.appointmentBooking.days.Tuesday.key]: en.appointmentBooking.days.Tuesday.value,
        [en.appointmentBooking.days.Wednesday.key]: en.appointmentBooking.days.Wednesday.value,
        [en.appointmentBooking.days.Thursday.key]: en.appointmentBooking.days.Thursday.value,
        [en.appointmentBooking.days.Friday.key]: en.appointmentBooking.days.Friday.value,
        [en.appointmentBooking.days.Saturday.key]: en.appointmentBooking.days.Saturday.value,
        [en.appointmentBooking.days.Sunday.key]: en.appointmentBooking.days.Sunday.value,



        // Form Fields
        [en.formFields.firstName.key]: en.formFields.firstName.value,
        [en.formFields.lastName.key]: en.formFields.lastName.value,
        [en.formFields.email.key]: en.formFields.email.value,
        [en.formFields.gender.key]: en.formFields.gender.value,
        [en.formFields.mobileNumber.key]: en.formFields.mobileNumber.value,
        [en.formFields.address.key]: en.formFields.address.value,
        [en.formFields.dateOfBirth.key]: en.formFields.dateOfBirth.value,
        [en.formFields.last4DigitsOfSSN.key]: en.formFields.last4DigitsOfSSN.value,
        [en.formFields.male.key]: en.formFields.male.value,
        [en.formFields.female.key]: en.formFields.female.value,
        [en.formFields.other.key]: en.formFields.other.value,
        [en.formFields.bookandConfirm.key]: en.formFields.bookandConfirm.value,
        [en.formFields.cancel.key]: en.formFields.cancel.value,
    },
  },
  es: {
    translation: {
      [es.choose_your_appointent_time.key]:
        es.choose_your_appointent_time.value,
      [es.login_text.key]: es.login_text.value,
      [es.calendarOptions.monthly.key]: es.calendarOptions.monthly.value,
      [es.calendarOptions.weekly.key]: es.calendarOptions.weekly.value,
      [es.calendarOptions["today:"].key]: es.calendarOptions["today:"].value,
      [es.previous.key]: es.previous.value,
      [es.next.key]: es.next.value,
      [es.bookAppointment.key]: es.bookAppointment.value,
      [es.reset.key]: es.reset.value,
      [es.language.key]: es.language.value,
      [es.navigation.dashboard.key]: es.navigation.dashboard.value,
      [es.navigation.appointmentList.key]: es.navigation.appointmentList.value,
      [es.navigation.settings.key]: es.navigation.settings.value,
      [es.navigation.appointmentBooking.key]:
        es.navigation.appointmentBooking.value,
      [es.navigation.users.key]: es.navigation.users.value,
      [es.therapistDashboard.appointmentsThisMonth.key]:
        es.therapistDashboard.appointmentsThisMonth.value,
      [es.therapistDashboard.appointmentsLeftToday.key]:
        es.therapistDashboard.appointmentsLeftToday.value,
      [es.therapistDashboard.TodaysAppointments]:
        es.therapistDashboard.TodaysAppointments,
      [es.therapistDashboard.clientsThisMonth.key]:
        es.therapistDashboard.clientsThisMonth.value,
      [es.therapistDashboard.OutstandingBalance.key]:
        es.therapistDashboard.OutstandingBalance.value,
      [es.therapistDashboard.NextAppointmentIn.key]:
        es.therapistDashboard.NextAppointmentIn.value,
      [es.therapistDashboard.TotalEarnings.key]:
        es.therapistDashboard.TotalEarnings.value,
      [es.therapistDashboard.TotalClients.key]:
        es.therapistDashboard.TotalClients.value,
      [es.therapistDashboard.HaveANiceDayAtWork.key]:
        es.therapistDashboard.HaveANiceDayAtWork.value,
      [es.statuses.pending.key]: es.statuses.pending.value,
      [es.statuses.completed.key]: es.statuses.completed.value,
      [es.statuses.onTime.key]: es.statuses.onTime.value,
      [es.statuses.cancelled.key]: es.statuses.cancelled.value,
      [es.statuses.followUp.key]: es.statuses.followUp.value,
      [es.statuses.rescheduled.key]: es.statuses.rescheduled.value,
      [es.initialAssessement.rules.completeTheFollowingStepsBy8AmOnYouApptDay
        .key]:
        es.initialAssessement.rules.completeTheFollowingStepsBy8AmOnYouApptDay
          .value,
      [es.initialAssessement.rules.beforeYourAppointment.key]:
        es.initialAssessement.rules.beforeYourAppointment.value,
      [es.initialAssessement.rules.payANonRefundableDeposit.key]:
        es.initialAssessement.rules.payANonRefundableDeposit.value,
      [es.initialAssessement.rules.depositHere.key]:
        es.initialAssessement.rules.depositHere.value,
      [es.initialAssessement.rules.includeYourFirstAndLastNameInThePaymentNotes
        .key]:
        es.initialAssessement.rules.includeYourFirstAndLastNameInThePaymentNotes
          .value,
      [es.initialAssessement.rules.register.key]:
        es.initialAssessement.rules.register.value,
      [es.initialAssessement.rules.asYourSAPInTheClearinghouseIfApplicable.key]:
        es.initialAssessement.rules.asYourSAPInTheClearinghouseIfApplicable
          .value,
      [es.initialAssessement.rules.fillOutTheNewClientFormAndAssessments.key]:
        es.initialAssessement.rules.fillOutTheNewClientFormAndAssessments.value,
      [es.initialAssessement.rules
        .theseStepsMustBeCompletedToAvoidAutomaticRescheduling.key]:
        es.initialAssessement.rules
          .theseStepsMustBeCompletedToAvoidAutomaticRescheduling.value,
      [es.initialAssessement.rules.minimumIsNeededToSecureYourBooking.key]:
        es.initialAssessement.rules.minimumIsNeededToSecureYourBooking.value,
      [es.initialAssessement.rules.Give48HoursNoticeToCancelOrReschedule.key]:
        es.initialAssessement.rules.Give48HoursNoticeToCancelOrReschedule.value,
      [es.initialAssessement.rules.fee.key]:
        es.initialAssessement.rules.fee.value,
      [es.initialAssessement.rules.SimplyCancelRebook.key]:
        es.initialAssessement.rules.SimplyCancelRebook.value,
      [es.initialAssessement.rules
        .noPreliminaryInformationCallIsNeededForBooking.key]:
        es.initialAssessement.rules
          .noPreliminaryInformationCallIsNeededForBooking.value,
      [es.initialAssessement.rules.contactSapflowAi.key]:
        es.initialAssessement.rules.contactSapflowAi.value,
      [es.followUpAppointment.content1.key]:
        es.followUpAppointment.content1.value,
      [es.followUpAppointment.content2.key]:
        es.followUpAppointment.content2.value,
      [es.followUpAppointment.content3.key]:
        es.followUpAppointment.content3.value,
      [es.followUpAppointment.content4.key]:
        es.followUpAppointment.content4.value,
      [es.followUpAppointment.content5.key]:
        es.followUpAppointment.content5.value,
      [es.followUpAppointment.title.key]: es.followUpAppointment.title.value,
      [es.appointmentBooking.appointmentDate.key]:
        es.appointmentBooking.appointmentDate.value,
      [es.appointmentBooking.appointmentTime.key]:
        es.appointmentBooking.appointmentTime.value,
      [es.appointmentBooking.duration.key]:
        es.appointmentBooking.duration.value,
      [es.appointmentBooking.minutes.key]: es.appointmentBooking.minutes.value,
      [es.appointmentBooking.appointmentDetails.key]:
        es.appointmentBooking.appointmentDetails.value,
        [es.appointmentBooking.days.Monday.key]: es.appointmentBooking.days.Monday.value,
        [es.appointmentBooking.days.Tuesday.key]: es.appointmentBooking.days.Tuesday.value,
        [es.appointmentBooking.days.Wednesday.key]: es.appointmentBooking.days.Wednesday.value,
        [es.appointmentBooking.days.Thursday.key]: es.appointmentBooking.days.Thursday.value,
        [es.appointmentBooking.days.Friday.key]: es.appointmentBooking.days.Friday.value,
        [es.appointmentBooking.days.Saturday.key]: es.appointmentBooking.days.Saturday.value,
        [es.appointmentBooking.days.Sunday.key]: es.appointmentBooking.days.Sunday.value,
        


        // Form Fields
        [es.formFields.firstName.key]: es.formFields.firstName.value,
        [es.formFields.lastName.key]: es.formFields.lastName.value,
        [es.formFields.email.key]: es.formFields.email.value,
        [es.formFields.mobileNumber.key]: es.formFields.mobileNumber.value,
        [es.formFields.address.key]: es.formFields.address.value,
        [es.formFields.dateOfBirth.key]: es.formFields.dateOfBirth.value,
        [es.formFields.last4DigitsOfSSN.key]: es.formFields.last4DigitsOfSSN.value,
        [es.formFields.male.key]: es.formFields.male.value,
        [es.formFields.female.key]: es.formFields.female.value,
        [es.formFields.other.key]: es.formFields.other.value,
        [es.formFields.bookandConfirm.key]: es.formFields.bookandConfirm.value,
        [es.formFields.cancel.key]: es.formFields.cancel.value,
        [es.formFields.gender.key]: es.formFields.gender.value,

    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const LocalizationHelper = i18n;
