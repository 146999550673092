import { createSlice } from "@reduxjs/toolkit";
import { Languages } from "common/constants";

const initialState = {
  value: Languages.EN,
};

export const languageSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const langActions = languageSlice.actions;

export const { reducer } = languageSlice;
