  import { useQuery } from "@tanstack/react-query";
import {
    getFollowUpEvaluation
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";
  
  export const useFetchFollowUpEvalution = (appointmentId) =>
    useQuery({
      queryKey: [AppQueriKeys.GET_FOLLOW_UP_REPORT, appointmentId],
      queryFn: () => getFollowUpEvaluation(appointmentId),
      enabled: !!appointmentId,

    });
  