import { useQuery } from "@tanstack/react-query";
import { AppQueriKeys } from "./AppQueries";
import { getAdminClientStats } from "services/ApiService";

export const useFetchAdminClientStats  = (timeframe) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_ADMIN_CLIENT_STATS, timeframe],
    queryFn: () =>
      getAdminClientStats(
        {
          timeframe,
        }
      ),
    enabled: !!timeframe,
  });
