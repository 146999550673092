import { useQuery } from "@tanstack/react-query";
import { fetchAppointmentListForTherapist, getTherapistDashboard } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchTherapistDashboard = (therapistId) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_THERAPIST_DASHBOARD,therapistId,],
    queryFn: () => getTherapistDashboard(),
    enabled: !!therapistId,
  });
