import { useQuery } from "@tanstack/react-query";
import { AppQueriKeys } from "./AppQueries";
import { getAdminDashboardDetails } from "services/ApiService";

export const useFetchAdminDashboard = () => {
  return useQuery({
    queryKey: [AppQueriKeys.GET_ADMIN_DASHBOARD_DETAILS],
    queryFn: () =>
      getAdminDashboardDetails(),
  });
}
