import { useActions } from "./useActions";


const useLoader = () => {
  const { incrementLoader, decrementLoader } = useActions();

  const withLoader = async (asyncFunc) => {
    try {
      incrementLoader();
      await asyncFunc();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      decrementLoader();
    }
  };

  return {
    withLoader,
  };
};

export default useLoader;