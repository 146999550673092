import { useQuery } from "@tanstack/react-query";
import { getTherapistClientStatistics } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchClientStats = (timeframe) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_THERAPIST_DASHBOARD_CLIENT_STATS, timeframe],
    queryFn: () =>
      getTherapistClientStatistics({
        timeframe,
      }),
    enabled: !!timeframe,
  });
