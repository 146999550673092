import { QueryClient } from "@tanstack/react-query";
import { Button, Result } from "antd";
import { isTherapist } from "helper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getUser } from "store/selectors";
import React, { useState } from "react";
import { createPaymentAccount } from "services/ApiService";
import { Route_Url } from "common/constants";

export default function PaymentCreationProvider({ children }) {
  const user = useSelector(getUser);
  const signedInAsTherapist = isTherapist(user);
  const paymentPending = !(user?.payments_enabled || user?.paypal_payments_enabled);
  const [loading, setLoading] = useState(false);
  const pathname = window.location.pathname;

  const title = `Payment Account Creation Pending`;

  const createAccount = async () => {
    setLoading(true);
    try {
      const redirectUrl = await createPaymentAccount(user?.id);
      window.location.href = redirectUrl;
    } catch (error) {
      console.log(error);
    }
  };
  
  const showBlocker =
    signedInAsTherapist &&
    paymentPending &&
    !pathname.includes(Route_Url.payment);
  if (showBlocker) {
    return (
      <section className="w-screen h-screen flex justify-center items-center">
        <Result
          status="warning"
          title={title}
          subTitle="Please complete your payment account creation to receive payments."
          extra={[
            <Button
              onClick={createAccount}
              loading={loading}
              className="bg-primary-dark text-white h-12"
              key="redirect"
            >
              Create Payment Account
            </Button>,
          ]}
        />
      </section>
    );
  }
  return children;
}

PaymentCreationProvider.propTypes = {
  children: PropTypes.node,
};
