import React, { useLayoutEffect, useState } from "react";
import { Route_Url } from "common/constants";
import { useActions } from "common/hooks";
import DynamicLoadingIndicator from "components/indicator/AuthLoadingIndicator";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import {
  FIREBASE_ERROR_MSG,
  getFirebaseAuth,
  Storage_Keys,
  StorageHelper,
  userStore,
} from "helper";
import { verifyUser } from "services/ApiService";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

export const AuthProviderContext = React.createContext();

/**
 * AuthProvider component
 * 
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to be rendered within the provider
 * 
 * @description
 * This component provides authentication context to its children. It handles user authentication
 * using Firebase, manages user state, and displays notifications based on authentication status.
 * 
 * @returns {JSX.Element} The rendered component
 */

export const AuthProvider = ({ children }) => {
  const auth = getFirebaseAuth();
  const [loading, setLoading] = useState(true);
  const { setUser, displayNotification, incrementLoader } = useActions();
  const [user, setUserState] = useState(null);
 
  /**
   *  *Load user details
   *  @description Load user details from the firebase
   * @param {*} isSignInWithLink 
   * @returns void
   */
  const loadUser = async (isSignInWithLink = false) => {
    try {
      const data = await verifyUser();
      setUser(data);
      setUserState(data);
      if (isSignInWithLink) {
        displayNotification({
          type: "success",
          message: `Welcome  ${data?.name} !!`,
          description: "You have logged in successfully",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      displayNotification({
        type: "error",
        message: `Error occurred while authenticating !!`,
        description: "Please contact the administrator",
      });
    }
  };
  
 /**
 * useLayoutEffect hook to handle user authentication and redirection.
 * 
 * - Retrieves email from local storage for sign-in.
 * - Extracts therapistId from URL and stores it.
 * - Handles email link sign-in and user verification.
 * - Updates user state and displays notifications based on authentication status.
 * 
 * @returns {void}
 */
  useLayoutEffect(() => {
    let email = window.localStorage.getItem("emailForSignIn");
    let search = window.location?.search;
    const therapistSearch = search?.split("therapistId=")?.[1];
    const therapistId = therapistSearch?.split("&")?.[0];
    const isSignIn = new URLSearchParams(search).get("mode") === "signIn";

    const verificationLink = window.location.href;

    if (therapistId) {
      StorageHelper.store(Storage_Keys.TherapistIDForClient, therapistId);
      window.history.pushState(
        null,
        null,
        `${Route_Url.AppointmentBooking}/${therapistId}`
      );
    }

    if (therapistId) {
      window.history.pushState(
        null,
        null,
        `${Route_Url.AppointmentBooking}/${therapistId}`
      );
    }

    if (isSignInWithEmailLink(auth, verificationLink)) {
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }

      signInWithEmailLink(auth, email, verificationLink)
        .then(async function (result) {
          if (!result?.emailVerified) {
            setLoading(false);
            return;
          }
          userStore.setUser(result);
          loadUser(true);
          window.localStorage.removeItem("emailForSignIn");
        })
        .catch(function (error) {
          displayNotification({
            type: "error",
            message: FIREBASE_ERROR_MSG[error?.code]?.message,
            description: FIREBASE_ERROR_MSG[error?.code]?.description,
          });
        });
    }

    auth.onAuthStateChanged((value) => {
      if (isSignIn && isEmpty(value)) {
        return;
      }
      if (!value?.emailVerified) {
        setLoading(false);
        return;
      }
      userStore.setUser(value);
      loadUser();
    });
  }, []);

  if (loading || (auth?.currentUser && !user)) {
    return <DynamicLoadingIndicator text="Checking the credentials ..." />;
  }
  return (
    <AuthProviderContext.Provider value={auth?.currentUser}>
      {children}
    </AuthProviderContext.Provider>
  );
};


AuthProvider.propTypes = {
  children: PropTypes.node,
};