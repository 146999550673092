import { API_ROUTES, ReportTypes } from "common/constants";
import { AxiosFactory, getBearerToken } from "helper";

const axios = AxiosFactory;

/**
 * Get User details
 * @returns
 */
const verifyUser = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getBearerToken();
      const response = await axios.post(API_ROUTES.PATIENT.verifyUser, {
        token,
      });
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get User By Email
 * @returns
 */
const getUserbyEmail = async (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getUser}?email=${email}`,
        {
          token,
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Calendar Bookings
 * @returns
 */
const getCalendarBookings = async (therapistId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getCalendarBookings}?therapist=${therapistId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Create Appointment
 * @returns
 */
const bookAppointment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.bookAppointment}`,
        data
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Fetch initial Assessment Questions
 * @returns
 */
const fetchInitialAssessmentQuestions = async (appointmentId, lang) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.PATIENT.getAssessmentQuestions}?appointmentId=${appointmentId}&language=${lang}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Submit  initial Assessment Answers
 * @returns
 */
const submitAssessmentAnswers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.submitQuestions}`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get initial Assessment Answers By Section
 * @returns
 */
const fetchAnswersForInitialAssessment = async (
  appointmentId,
  sectionNumber,
  lang = "en"
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.getAssessmentSavedResponse}?appointmentId=${appointmentId}&language=${lang}&sectionNumber=${sectionNumber}`,
        data
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Fetch the appointment list for the therapist
 * @returns
 */
const fetchAppointmentListForTherapist = async (
  pageLimit = 10,
  pageNumber = 1,
  search = "",
  sort,
  gt = new Date().toISOString(),
  timestampField = "appointmentEndTimestamp",
  searchFields = "client.firstName,client.lastName,client.email,client.mobile"
) => {
  const sortBy =
    sort?.field && sort?.order
      ? `${sort.field}:${sort.order}`
      : "appointmentStartTimestamp:asc";
  let apiUrl = `${API_ROUTES.DOCTOR.getAppointmentList}?&limit=${pageLimit}&page=${pageNumber}&sortBy=${sortBy}`;
  if (search) {
    apiUrl += `&search=${search}&searchFields=${searchFields}`;
  } else {
    apiUrl += `&gt=${gt}&timestampField=${timestampField}`;
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${apiUrl}`);
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Fetch the appointment details by id
 * @returns
 */
const fetchAppointmentDetailsById = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getAppointmentDetails}?id=${appointmentId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Fetch the appointment details by id
 * @returns
 */
const updateAppointmentStatus = async (payload, appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.updateAppointmentStatus}?id=${appointmentId}`,
        payload
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Fetch the subscriptions plans
 * @returns
 */
const getSubscriptions = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_ROUTES.DOCTOR.getSubscriptions}`);
      resolve(response.data?.results);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Create new user for therapist
 * @returns
 */
const createStripeSubscription = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PAYMENT.createStripeSubscription}`,
        payload
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Initial Evaluation Questions
 * @returns
 */
const getInitialEvaluationQuestions = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getInitialEvaluationPlan}?appointmentId=${appointmentId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Initial Evaluation Questions
 * @returns
 */
const getTreatmentPlan = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getTreatmentPlan}?appointmentId=${appointmentId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get User By id
 * @returns
 */
const getUserById = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getUser}/${userId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Submit  initial Assessment Answers
 * @returns
 */
const submitInitialEvaluationAnswers = async (data, appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.submitInitialEvaluationAnswers}?appointmentId=${appointmentId}`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Submit  initial Assessment Answers
 * @returns
 */
const submitTreatmentPlanAnswers = async (data, appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.submitTreatmentPlan}?appointmentId=${appointmentId}`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Initial Report
 * @returns
 */
const getInitialReport = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.getInitialReport}`,
        { appointmentId },
        { responseType: "arraybuffer" }
      );
      resolve(response?.data);
    } catch (error) {
      if (error.response && error.response.data instanceof ArrayBuffer) {
        const buffer = error.response.data;
        const text = new TextDecoder("utf-8").decode(buffer);
        let errorMessage = text;
        try {
          const json = JSON.parse(text);
          errorMessage = json.message || errorMessage;
        } catch (e) {
          // If parsing fails, use the original text
        }
        reject(new Error(errorMessage));
      } else {
        reject(error?.response?.data);
      }
    }
  });
};

/**
 * Generate initial report
 * @returns
 */
const generateInitialReport = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.generateInitialReport}`,
        { appointmentId },
        { responseType: "arraybuffer" }
      );
      resolve(response?.data);
    } catch (error) {
      if (error.response && error.response.data instanceof ArrayBuffer) {
        const buffer = error.response.data;
        const text = new TextDecoder("utf-8").decode(buffer);
        let errorMessage = text;
        try {
          const json = JSON.parse(text);
          errorMessage = json.message || errorMessage;
        } catch (e) {
          // If parsing fails, use the original text
        }
        reject(new Error(errorMessage));
      } else {
        reject(error?.response?.data);
      }
    }
  });
};

/**
 * Send intial report through mail
 * @returns
 */
const sendReportMail = async (appointmentId, reportType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API_ROUTES.DOCTOR.sendReportMail}`, {
        appointmentId,
        reportType,
      });
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Send initial Payment Intent
 * @returns
 */
const initiateStripePayment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PAYMENT.initiateStripePayment}`,
        data
      );
      resolve(response.data);
    } catch (error) {
      console.log(error);
    }
  });
};

/**
 * Get Payment Info
 * @returns
 */
const getPaymentInfoForAppointment = (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PAYMENT.getAppointementDetails}`,
        { appointmentId }
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Invoice URL
 * @returns
 */
const getPaymentInvoice = (sessionId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API_ROUTES.PAYMENT.getInvoice}`, {
        sessionId,
      });
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Therapist Dashboard Details
 * @returns
 */
const getTherapistDashboard = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_ROUTES.DOCTOR.dashboard}`);
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Create Payment Account
 * @returns
 */
const createPaymentAccount = (therapistId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API_ROUTES.PAYMENT.createAccount}`, {
        therapistId,
      });
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Create Paypal  Payment Account
 * @returns
 */
const createPaypalPaymentAccount = (therapistId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PAYMENT.createPaypalAccount}`,
        {
          therapistId,
        }
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Create Payment Account
 * @returns
 */
const fetchEvidenceSubmissionList = (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getEvidenceSubmission}?appointmentId=${appointmentId}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Save Evidence Details with File Upload
 * @returns
 */
const saveEvidenceDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.saveEvidence}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Delete Evidence Details with File Upload
 * @returns
 */
const deleteEvidence = (appointmentId, baseSection) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.deleteEvidence}?appointmentId=${appointmentId}&baseSection=${baseSection}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Create Paypal Order
 * @returns
 */
const createPaypalOrder = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PAYMENT.createPaypalOrder}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Capture Paypal Order
 * @returns
 */
const capturePaypalOrder = (orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PAYMENT.capturePaypalOrder}`,
        {
          orderId,
        }
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Create Paypal subcription
 * @returns
 */
const createPaypalSubscription = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PAYMENT.createPaypalSubscription}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Therapist Dashboard Client Statistics
 * @returns
 */
const getTherapistClientStatistics = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.getTherapistClientStats}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Therapist Dashboard Client Statistics
 * @returns
 */
const getTherapistEarningStatistics = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.getTherapistEarningStats}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Follow Up Evaluation Questions
 * @returns
 */
const getFollowUpEvaluation = (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getFollowUpEvaluation}?appointmentId=${appointmentId}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Follow Up Report
 * @returns
 */

const getFollowUpReport = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.getFollowUpReport}`,
        { appointmentId },
        { responseType: "arraybuffer" }
      );
      resolve(response?.data);
    } catch (error) {
      if (error.response && error.response.data instanceof ArrayBuffer) {
        const buffer = error.response.data;
        const text = new TextDecoder("utf-8").decode(buffer);
        let errorMessage = text;
        try {
          const json = JSON.parse(text);
          errorMessage = json.message || errorMessage;
        } catch (e) {
          // If parsing fails, use the original text
        }
        reject(new Error(errorMessage));
      } else {
        reject(error?.response?.data);
      }
    }
  });
};

/**
 * Submit Follow Up Evaluation
 * @returns
 */
const submitFollowUpEvaluation = async (data, appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.saveFollowUpEvaluation}?appointmentId=${appointmentId}`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Reschedule the previous appointment
 * @param {*} data - appointmentId, appointmentStartTime
 * @returns
 */
const rescheduleAppointment = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.rescheduleAppointment}?`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Cancel the previous appointment
 * @param {*} data - appointmentId, appointmentStartTime
 * @returns
 */
const cancelAppointment = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.cancelAppointment}?`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get User Settings
 * @param {string} userId
 * @returns
 */
const getUserSettings = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.SETTINGS.getUserSettings}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Save User Settings
 * @param {string} userId
 * @returns
 */
const saveUserSettings = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.SETTINGS.saveUserSettings}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * *Save Feedback
 * @param {JSON} payload
 * @returns
 */
const saveFeedback = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.FEEDBACK.saveFeedback}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

const fetchReportRequestDetails = async (lang, therapistId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.PATIENT.getAdditionalReportQuestions}?language=${lang}&therapistId=${therapistId}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};
const saveAdditionalReportRequest = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.submitAdditionalReportQuestions}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

const updateAdditionalReportRequest = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.updateAdditionalReportRequest}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 * Get Follow Up Report
 * @returns
 */

const getClientAdditionalReport = async (
  appointmentId,
  reportType = ReportTypes.initialEvaluation
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.REPORTS.getClientAdditionalReport}`,
        { appointmentId, reportType },
        { responseType: "arraybuffer" }
      );
      resolve(response?.data);
    } catch (error) {
      if (error.response && error.response.data instanceof ArrayBuffer) {
        const buffer = error.response.data;
        const text = new TextDecoder("utf-8").decode(buffer);
        let errorMessage = text;
        try {
          const json = JSON.parse(text);
          errorMessage = json.message || errorMessage;
        } catch (e) {
          // If parsing fails, use the original text
        }
        reject(new Error(errorMessage));
      } else {
        reject(error?.response?.data);
      }
    }
  });
};

/**
 *  Get Admin Payment Stats
 * @returns
 */
const getAdminPaymentStats = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.ADMIN.getPaymentStats}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};
/**
 *  Get Admin Client Stats
 * @returns
 */
const getAdminClientStats = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.ADMIN.getClientStats}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 *  Get Admin Dashboard Details
 * @returns
 */
const getAdminDashboardDetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.ADMIN.getDashboardDetails}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 *  Get Admin Dashboard Details
 * @returns
 */
const getClientDashboardDetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.PATIENT.getDashboardDetails}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

/**
 *  Get Users List
 * @returns
 */
const getUsersList = (role, pageLimit, pageNumber, sort) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.ADMIN.getUserList}?role=${role}&limit=${pageLimit}&page=${pageNumber}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

const exportAppointmentList = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.exportAppointmentList}`,
        payload,
        { responseType: "arraybuffer" }
      );
      resolve(response?.data);
    } catch (error) {
      if (error.response && error.response.data instanceof ArrayBuffer) {
        const buffer = error.response.data;
        const text = new TextDecoder("utf-8").decode(buffer);
        let errorMessage = text;
        try {
          const json = JSON.parse(text);
          errorMessage = json.message || errorMessage;
        } catch (e) {
          // If parsing fails, use the original text
        }
        reject(new Error(errorMessage));
      } else {
        reject(error?.response?.data);
      }
    }
  });
};

const createOfficeManager = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.createOfficeManager}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
}

const createAdmin = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.createAdmin}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

const fetchAppointmentByQueries = async (
  pageLimit = 10,
  pageNumber = 1,
  queries = ''
) => {
  const timestampField = "appointmentStartTimestamp";
  const sortBy = "appointmentStartTimestamp:asc";
  let apiUrl = `${API_ROUTES.DOCTOR.getAppointmentList}?&limit=${pageLimit}&page=${pageNumber}&sortBy=${sortBy}&timestampField=${timestampField}`;
  if(queries) {
    apiUrl += `&${queries}`
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${apiUrl}`);
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

const saveOutlookTokens = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.saveOutlookToken}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

const createCustomTokenForMicrosoftUsers = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.createCustomTokenForMicrosoftUsers}`,
        payload
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};

const getTherapistQuestionTemplate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getQuestionTemplate}`,
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
}

const saveTherapistQuestionTemplate = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.saveQuestionTemplate}`,data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
}

/**
 * Invoke notifications API to get all notifications for the given user email id
 * @param {string} email - The email of the user
 * @returns {Promise} - The promise object representing the result of the API call  
 */
const getNotifications = async (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_ROUTES.NOTIFICATIONS.getNotifications}?limit=10&sortBy=createdAt:desc`);
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
}

/**
 * Mark the notification as read for the given list of notification ids
 */
const markNotificationAsRead = async (notificationIds) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API_ROUTES.NOTIFICATIONS.markAsRead}`, { notificationIds });
      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
}

export {
  verifyUser,
  getUserbyEmail,
  getCalendarBookings,
  bookAppointment,
  fetchInitialAssessmentQuestions,
  submitAssessmentAnswers,
  fetchAnswersForInitialAssessment,
  fetchAppointmentListForTherapist,
  fetchAppointmentDetailsById,
  updateAppointmentStatus,
  getSubscriptions,
  getInitialEvaluationQuestions,
  getUserById,
  submitInitialEvaluationAnswers,
  getTreatmentPlan,
  submitTreatmentPlanAnswers,
  getInitialReport,
  generateInitialReport,
  sendReportMail,
  getTherapistDashboard,
  fetchEvidenceSubmissionList,
  saveEvidenceDetails,
  deleteEvidence,
  getTherapistClientStatistics,
  getTherapistEarningStatistics,
  getFollowUpEvaluation,
  getFollowUpReport,
  submitFollowUpEvaluation,
  rescheduleAppointment,
  cancelAppointment,
  exportAppointmentList,
  getClientDashboardDetails,
  saveOutlookTokens,
  createCustomTokenForMicrosoftUsers,  
  getTherapistQuestionTemplate,
  
  // Payments
  initiateStripePayment,
  getPaymentInfoForAppointment,
  getPaymentInvoice,
  createPaypalOrder,
  capturePaypalOrder,
  createPaymentAccount,
  createPaypalSubscription,
  createStripeSubscription,
  createPaypalPaymentAccount,

  // Settings
  getUserSettings,
  saveUserSettings,

  // Report Request
  fetchReportRequestDetails,
  saveAdditionalReportRequest,
  updateAdditionalReportRequest,
  getClientAdditionalReport,

  // feedback
  saveFeedback,

  // Admin Dashboard Stats
  getAdminPaymentStats,
  getAdminClientStats,
  getAdminDashboardDetails,
  getUsersList,
  createOfficeManager,
  createAdmin,

  // Office Manager
  fetchAppointmentByQueries,
  saveTherapistQuestionTemplate,

  // Notifications
  getNotifications,
  markNotificationAsRead
};
