import { useQuery } from "@tanstack/react-query";
import { getTherapistEarningStatistics } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchPaymentStats = (timeframe) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_THERAPIST_DASHBOARD_PAYMENT_STATS, timeframe],
    queryFn: () =>
      getTherapistEarningStatistics({
        timeframe,
      }),
    enabled: !!timeframe,
  });
