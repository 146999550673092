import React, { useEffect } from "react";
import { Radio, Skeleton } from "antd";
import { PaymentIcons, PaymentTypes } from "common/constants";
import { useFetchSubscriptionPlans } from "common/hooks/queries";

import { scrollToTop } from "helper";

export default function SubscriptionPayment(props) {
  const { amount, paymentType, selectPayment } = props;

  useEffect(() => {
    scrollToTop();
  }, []);
  

  return (
    <section className=" px-10 min-h-[40dvh] gap-4">
      <Radio.Group
        value={paymentType}
        size="large"
        onChange={(e) => selectPayment(e.target.value)}
      >
        {PaymentTypes.map((payment) => (
          <Radio className="w-full py-5" value={payment} key={payment}>
            <div className="flex flex-row flex-wrap justify-between items-center gap-4">
              {/* <span className="text-lg font-normal">{payment}</span> */}
              {payment === "Stripe" && (
                <span className="text-sm font-medium px-2">
                  {"Credit/Debit Card"}
                </span>
              )}
              <img src={PaymentIcons[payment]} className="inline-block mx-2" />
            </div>
          </Radio>
        ))}
      </Radio.Group>
    </section>
  );
}
