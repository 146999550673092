import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  signInWithCredential,
  signInWithCustomToken,
} from "firebase/auth";
import {
  dispatchLoadUser,
  dispatchNotification,
  hideLoader,
  showLoader,
} from "./StoreHelper";
import { Test_Therapist_Id } from "common/constants";
import {
  saveOutlookTokens,
  createCustomTokenForMicrosoftUsers,
  verifyUser,
} from "services/ApiService";
import { decrementLoader, incrementLoader } from "store/action-creators";
import { getDefaultRoute } from "./RouteHelper";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const checkifEmailVerified = () => {
  const auth = getAuth();
  return auth?.currentUser?.emailVerified;
};

export const getFirebaseAuth = () => {
  const app = initializeApp(firebaseConfig);
  return getAuth(app);
};

// Get ID token to append on Authorization Headers
export const getBearerToken = async () => {
  const auth = getAuth();
  if (!auth?.currentUser?.emailVerified) return "";
  try {
    const token = await auth?.currentUser?.getIdToken();
    return token;
  } catch (error) {
    console.log(error);
  }
};

export const signInUsingEmailAddress = (emailAddress, queryParams) => {
  const auth = getFirebaseAuth();
  const continueUrl = queryParams
    ? `${process.env.REACT_APP_REDIRECT_URI}${queryParams}`
    : `${process.env.REACT_APP_REDIRECT_URI}`;
  const actionCodeSettings = {
    url: continueUrl, // Same URL where the app is hosted
    handleCodeInApp: true,
  };
  return new Promise((resolve) => {
    sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings)
      .then(async function () {
        resolve();
        window.localStorage.setItem("emailForSignIn", emailAddress);
      })
      .catch(function (error) {
        console.log(error);
        dispatchNotification(
          "error",
          error?.code,
          "Please contact the adminitstrator"
        );
      });
  });
};

export const googleSignUp = async () => {
  try {
    const auth = getFirebaseAuth();
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result?.user;
    const fullName = user?.displayName;
    const [firstName, lastName] = fullName ? fullName?.split(" ") : ["", ""];
    const requestPayload = {
      email: user?.email,
      firstName: firstName,
      lastName: lastName,
      role: "doctor",
      photoURL: user?.photoURL,
      mobile: user?.phoneNumber,
    };
    const response = await googleSignin(requestPayload);
    if (response?.status >= 200 && response?.status < 300) {
      // Handle successful login
      const data = response?.data;
      dispatchNotification("success", "Signed us succcessfully");
      dispatchLoadUser();
      return data;
    } else {
      // Handle errors based on status code
      dispatchNotification("error", response?.data?.message);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const completeSignInWithGoogle = async (accessToken, navigate) => {
  try {
    showLoader();
    const auth = getFirebaseAuth();
    // Create a credential with the access token
    const credential = GoogleAuthProvider.credential(accessToken);
    // Sign in with the credential
    const response = await signInWithCredential(auth, credential);
    if (response?.user?.emailVerified) {
      verifyUser().then((response) => {
        if (response) {
          hideLoader();
          navigate(`${getDefaultRoute(response)}`);
          hideLoader();
        }
      });
    }
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

export const handleLogout = () => {
  const auth = getFirebaseAuth();
  if (!auth?.currentUser?.emailVerified) return;
  auth?.signOut().then(() => {
    window.localStorage.clear();
    window.localStorage.clear();
    window.location.reload();
  });
};

export const copyProfileUrl = async (userId) => {
  if (!userId) {
    console.error("User ID is required.");
    return;
  }
  const profileUrl = `${window.location.origin}/client/appointmentbooking/${userId}`;
  try {
    await navigator.clipboard.writeText(profileUrl);
    dispatchNotification("success", "Copied to clipboard");
  } catch (error) {
    console.error("Failed to copy profile URL:", error);
    dispatchNotification("error", "Failed to copy profile URL");
  }
};

export const copyReportReqURL = async (userId) => {
  if (!userId) {
    console.error("User ID is required.");
    return;
  }
  const profileUrl = `${window.location.origin}/client/requestreport/${userId}`;
  try {
    await navigator.clipboard.writeText(profileUrl);
    dispatchNotification("success", "Copied to clipboard");
  } catch (error) {
    console.error("Failed to copy report request URL:", error);
    dispatchNotification("error", "Failed to copy report request URL");
  }
};

export const signInWithMicrosoft = async () => {
  try {
    const codeVerifier = generateCodeVerifier();
    generateCodeChallenge(codeVerifier).then((codeChallenge) => {
      sessionStorage.setItem("code_verifier", codeVerifier);
      const authorizeUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`;
      const params = new URLSearchParams({
        client_id: process.env.REACT_APP_OUTLOOK_AUTH_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_OUTLOOK_AUTH_REDIRECT_URI,
        scope: "openid profile email Calendars.ReadWrite Calendars.Read User.Read offline_access",
        response_type: "code",
        response_mode: "query",
        code_challenge: codeChallenge, // Add PKCE code challenge
        code_challenge_method: "S256", // Specify SHA256 as the method
        prompt: "consent", // Force the consent prompt
      });
      window.location.href = `${authorizeUrl}?${params.toString()}`;
    });
  } catch (error) {
    console.error("Error during sign-in:", error);
  }
};

function generateCodeVerifier() {
  const randomBytes = new Uint8Array(32);
  window.crypto.getRandomValues(randomBytes);
  return base64UrlEncode(randomBytes);
}

function generateCodeChallenge(codeVerifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  return sha256(data).then((hash) => {
    return base64UrlEncode(hash);
  });
}

// Utility functions for Base64 URL encoding and SHA256
function base64UrlEncode(buffer) {
  let base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function sha256(buffer) {
  return crypto.subtle.digest("SHA-256", buffer);
}

export const completeSignInWithMicrosoft = async (payload, userProfile) => {
  try {
    const response = await saveOutlookTokens(payload);
    if (
      response.message &&
      response.message === "Successfully saved outlook tokens"
    ) {
      const customToken = await createCustomTokenForMicrosoftUsers(userProfile);
      const auth = getFirebaseAuth();
      const signInResponse = await signInWithCustomToken(auth, customToken);
      if (signInResponse?.user?.emailVerified) {
        dispatchLoadUser();
      }
    } else {
      console.error("Error during saving of outlook auth tokens:", response);
    }
  } catch (error) {
    console.error("Error during sign-in:", error);
    dispatchNotification(
      "error",
      "Login failed. Please check your credentials."
    );
  }
};

export const paypalOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  "enable-funding": "venmo",
  "disable-funding": "",
  "buyer-country": "US",
  debug: "false",
  currency: "USD",
  "data-page-type": "product-details",
  components: "buttons",
  "data-sdk-integration-source": "developer-studio",
};
