export const AppQueriKeys = {
  GET_ASSESSMENT_QUESTIONS: "getAssessmentQuestions",
  GET_ASSESSMENT_ANSWERS: "getAssessmentAnswers",
  GET_APPOINTMENT_LIST: "getAppointmentlist",
  GET_APPOINTMENT_DETAILS: "getAppointmentDetails",
  GET_SUBSCRIPTION_PLAN: "getSubscriptionPlan",
  GET_INITIAL_EVALUATION_QUESTIONS: "getInitialEvaluationQuestions",
  GET_TREATMENT_PLAN: "getTreatmentPlan",
  GET_USER_BY_ID: "getUserById",
  GET_INITIAL_REPORT: "generateInitialReport",
  GET_PAYMENT_INFO: "getPaymentInfo",
  GET_INVOICE_URL: "getInvoiceUrl",
  GET_THERAPIST_DASHBOARD: "getTherapistDashboard",
  GET_EVIDENCE_SUBMISSION: "getEvidenceSubmission",
  GET_THERAPIST_DASHBOARD_CLIENT_STATS: "getTherapistDashboardClientStats",
  GET_THERAPIST_DASHBOARD_PAYMENT_STATS: "getTherapistDashboardPaymentStats",
  GET_FOLLOW_UP_EVALUATION: "getFollowUpEvaluation",
  GET_FOLLOW_UP_REPORT: "getFollowUpReport",
  GET_USER_SETTINGS: "getUserSettings",
  GET_ADDITIONAL_REPORT_QUESTIONS: "getAdditionalReportQuestions",
  GET_CLIENT_ADDITIONAL_REPORT: "getClientAdditionalReport",
  GET_ADMIN_CLIENT_STATS: "getAdminClientStats",
  GET_ADMIN_PAYMENT_STATS: "getAdminPaymentStats",
  GET_ADMIN_DASHBOARD_DETAILS: "getAdminDashboardDetails",
  GET_USER_LIST: "getUserList",
  GET_CLIENT_DASHBOARD_DETAILS: "getClientDashboardDetails",
  GET_APPOINTMENT_BY_QUERIES: "getAppointmentByQueries",
  GET_THERAPIST_QUESTION_TEMPLATE: "getTherapistQuestionTemplate",
};
